import React, { useEffect } from 'react';
import ComeUpPageWrapper from '../components/ComeUpPageWrapper';
import ProcessCard from '../components/processPage/Process';
import { Link } from 'react-router-dom';
import Button from '../components/buttons/Button';
import processData from '../content/Process.json';

const Process = () => {
    useEffect(() => { document.title = "our process" }, []);

    return (
        <ComeUpPageWrapper>
            {/* <div className="max-w-[1500px] m-auto px-3">
                <div className="flex flex-col sm:flex-row items-start justify-between gap-4">
                <div className="flex-grow"><h1 className='text-4xl sm:w-[300px] text-orange-600 font-medium'>Our Approach</h1></div>
                    <p className='sm:w-[65%] text-2xl  font-light'>
                        Our team of dedicated experts specialises in creating tailored digital solutions that perfectly
                        fit your unique vision. From the initial idea to the final execution, we’re with you every step of
                        the way, making sure your digital journey is smooth and successful. Here's a look at how we
                        bring your ideas to life!
                    </p>
                </div> 
            </div> */}
            <div className='max-w-[1500px] m-auto mt-14'>
                <div className="flex flex-col sm:flex-row items-start justify-between gap-4">
                    <div className="flex-grow w-[254.7px] sm:w-[300px]">
                        <h1 className='text-4xl text-orange-600 font-medium'>Our Approach</h1>
                    </div>
                    <p className='sm:w-[65%] text-2xl  font-light' style={{ height: '100%' }}>
                        Our team of dedicated experts specialises in creating tailored digital solutions that perfectly
                        fit your unique vision. From the initial idea to the final execution, we’re with you every step of
                        the way, making sure your digital journey is smooth and successful. Here's a look at how we
                        bring your ideas to life!
                    </p>
                </div>
            </div>
            {processData.map((step, index) => (
                <ProcessCard
                    key={index}
                    img={step.img}
                    index={step.index}
                    title={step.title}
                    description={step.description}
                />
            ))}

            <div className='max-w-[1500px] m-auto mt-14'>
                <div className="flex flex-col sm:flex-row items-start justify-between gap-4">
                    <div className="flex-grow w-[254.7px] sm:w-[300px] opacity-0">
                        <h1 className='text-4xl text-orange-600 font-medium'>Our Approach</h1>
                    </div>
                    <div className='sm:w-[65%] text-2xl  font-light' style={{ height: '100%' }}>
                    <p className='mt-6 text-orange-500 text-2xl'>
                    We don’t just solve challenges; we drive digital transformation. By partnering with top
                    agencies and companies, we stay focused on making a meaningful impact.
                </p>

                <div className="mt-10">
                    <p>Have a digital project in mind?</p>
                    <Link to={"/contact-us"}><Button className='mt-2 text-3xl'>Contact us</Button></Link>
                </div>
                    </div>
                </div>
            </div> 
        </ComeUpPageWrapper>
    );
};

export default Process;
