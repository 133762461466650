import React from 'react';
import Button from '../../buttons/Button';
import Expertise from '../../Expertise';
import { Link } from 'react-router-dom';
import servicesData from '../../../content/Expertise.json';

const ExpertiseSection = () => {
    return (
        <section
            onClick={() => localStorage.setItem("scroll-position", "expertise")}
            id="expertise"
            className="py-20 bg-slate-10 bg-gray-900 text-white px-10 flex flex-col items-center text-center md:text-left md:flex-row md:items-start md:gap-28 gap-20 justify-around"
        >
            <div className="md:sticky top-20">
                <div className="sticky top-10 flex flex-col gap-3">
                    <h1 className='text-5xl md:text-[4.5vw] whitespace-nowrap md:text-left  text-orange-600'>
                        Our Services
                    </h1>
                    <p className="text-xl md:text-sm  w-full md:max-w-[180px]">Services dedicated to enhancing your success</p>
                    <Link to="/services" className="font-light text-2xl md:text-sm">
                        <Button>View all services</Button>
                    </Link>
                </div>
            </div>
            <div className="text-4xl md:text-[3.35vw] gap-9 md:gap-6 sm:text-6xl tracking-wider group flex items-center md:items-start flex-col">
                {servicesData.map((service, index) => (
                    <Link key={index} to={service.path}>
                        <Expertise>{service.name}</Expertise>
                    </Link>
                ))}
            </div>
        </section>
    );
};

export default ExpertiseSection;
