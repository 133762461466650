import React from 'react'
import { Link } from 'react-router-dom'

export interface Potrait{
    img:any,
    name:string,
    tags:string[],
    size:"sm"|"md"|"lg",
    href:string
}
interface PropTypes{
    potrait:Potrait,
    onHover?:()=>any
    onLeave?:()=>any
    overlay?:boolean | null
}
const GalleryPotrait = ({potrait:{size,img,name,tags,href},onHover,onLeave,overlay}:PropTypes) => {
  const immg = require(`../media/${img}`) 
  const w:Record<Potrait['size'],string> = {
    "lg":"",
    "md":"w-[75%]",
    "sm":"w-[65%]",
  }
  return (
        <Link target='_blank' to={href} className={`transition duration-300 space-y-2 ${overlay && "grayscale opacity-25"}`}>
            <img loading="lazy"  style={{aspectRatio:"310/175"}} alt='' onMouseEnter={onHover} onMouseLeave={onLeave} className={`transition duration-500 w- ${w[size]}  object-cover object-center rounded-lg hover:scale-[0.98]`} width={5300} src={immg} />
            <h3 className='font-medium'>{name}</h3>
            <p className='!mt-0 text-gray-500'>{tags.join(", ")}</p>
        </Link>
    )
}

export default GalleryPotrait
