import React, { useEffect } from "react";
import { m, useAnimation, useMotionValue, useSpring } from "framer-motion";
import { ArrowRight } from "lucide-react";
const logo = require("../../../media/logo.png");

const BulgingCircle: React.FC = () => {
  const rotation = useMotionValue(0);
  const smoothRotation = useSpring(rotation, { stiffness: 15, damping: 45 });
  const controls = useAnimation();
  useEffect(() => {
    if (!controls) return;
    controls.start({ rotate: 360 }, { duration: 13, repeat: Infinity, ease: "linear" });
    const handleMouseMove = (e: MouseEvent) => {
      controls.stop()
      const { clientX, clientY } = e;
      const centerX = window.innerWidth / 2;
      const centerY = window.innerHeight / 2;
      const x = clientX - centerX;
      const y = clientY - centerY;
      const angle = Math.atan2(y, x) * (180 / Math.PI);
      rotation.set(angle);
      controls.start({ rotate: 360 }, { duration: 13, repeat: Infinity, ease: "linear" });
    };

    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [controls,rotation]);

  return (
    <div
      className="w-screen h-screen p-4 relative overflow-hidden flex flex-col justify-center items-center"
    >

      <m.svg
        viewBox="0 0 200 200"
        xmlns="http://www.w3.org/2000/svg"
        className="md:size-[80em] lg:size-[146em] size-[80em] absolute -bottom-[30em] lg:-bottom-[83em] "
        style={{ rotate: smoothRotation }}
        animate={controls}
      >
        {/* <defs>
          <linearGradient id="gradient-orange" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" stopColor="#FF4500" /> 
            <stop offset="50%" stopColor="#ff7700" />  
            <stop offset="100%" stopColor="#ff4000" />  
          </linearGradient>
        </defs> */}
        <path
          className="drop-shadow-md //fill-orange-600 fill-gray-300"
          fill="url(#gradient-orange)"
          d="M41.6,-71.7C53.1,-65.4,60.9,-52.6,67.2,-39.5C73.5,-26.5,78.2,-13.2,79,0.5C79.9,14.2,76.9,28.4,71,42.2C65.1,55.9,56.3,69.1,44,75.1C31.7,81.1,15.9,79.8,0,79.7C-15.8,79.7,-31.7,81,-45.2,75.8C-58.8,70.5,-70.1,58.8,-76.3,45.1C-82.6,31.3,-83.7,15.7,-84.3,-0.3C-84.9,-16.3,-84.9,-32.7,-77.2,-43.9C-69.6,-55.2,-54.3,-61.3,-40.1,-66.1C-26,-70.8,-13,-74.1,1,-75.9C15.1,-77.7,30.1,-78,41.6,-71.7Z"
          transform="translate(100 100)"
        />
      </m.svg>
      <div
        className="relative z-50  flex flex-col justify-center items-center transform md:w-[50%] w-[90%] "
      >
        <m.i
          variants={{
            visible: { y: 0, opacity: 1 },
            hidden: { y: -170, opacity: 0 }
          }}
          initial={"hidden"}
          animate={"visible"}
          className="mb-6"
          transition={{
            type: "spring",
            stiffness: 100,
            duration: 1,
            delay: 1
          }}
        >
          <img loading="lazy"  src={logo} width={345} className='opacity-100' alt='clekk logo' />
        </m.i>
        <m.h1
        style={{backgroundColor:"#F79420"}}
          className="
          text-center overflow-hidden w-full md:max-w-[400px] sm:max-w-[400px] 
          mb-5 text-3xl sm:text-[4.25ch] 
          py-2 px-2 text-white font-extralight rounded-lg 
          bg-orange-400 backdrop-blur-md border border-white/20 shadow-lg
        "
        >
          &nbsp;Beyond The Frame&nbsp;
        </m.h1>

        {/* <h1
          className="
    text-center text-xl 
    font-semibold tracking-tight leading-snug 
    drop-shadow-lg
    bg-gradient-to-r from-pink-400 via-purple-400 to-cyan-400 
    text-transparent bg-clip-text
  "
        >
          Transforming Visions into Digital Excellence
        </h1> */}






        <m.button
          className='block overflow-hidden px-2 py-1 border rounded-full border-black m-auto mt-1 w-48  text-white border-black'
          variants={{
            visible: { opacity: 1, y: 0 },
            hidden: { opacity: 0, y: 25 }
          }}
          initial={"hidden"}
          animate={"visible"}
          transition={{
            type: "spring",
            stiffness: 175,
            duration: 5.5,
            delay: 2
          }}
        >
            <button onClick={()=>{
              document.getElementById("showreel")?.scrollIntoView()
            }} className='whitespace-nowrap text-gray-900 transition-all duration-500 w-[9.65rem] -translate-x-[38.5px] hover:-translate-x-2'>
              <i className='inline'>
                <ArrowRight className='inline' size={25} />
              </i>
              &nbsp;Let's Work Together&nbsp;
              <i className='inline'>
                <ArrowRight className='inline' size={25} />
              </i>
            </button>
        </m.button >
      </div>
      {/* <BlobButton /> */}
    </div>
  );
};

export default BulgingCircle;
