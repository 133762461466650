import React, { useEffect } from 'react';
import ComeUpPageWrapper from '../components/ComeUpPageWrapper';
import Service from '../components/servicesPage/Service';
import Button from '../components/buttons/Button';
import { Link } from 'react-router-dom';
import servicesData from '../content/Services.json';

const Services = () => {
  useEffect(() => { document.title = "services" }, []);

  return (
    <ComeUpPageWrapper>
      {/* <div className="flex flex-col text-center sm:text-left sm:flex-row justify-around max-w-[1500px] m-auto">
        <h1 className='text-4xl text-orange-600 font-medium'>Our Services</h1>
        <p className='sm:w-[65%] text-2xl font-normal'>
        We specialize in turning your digital vision into reality. Our team of experts works closely with you to develop tailored solutions that perfectly suit your unique needs. From initial brainstorming to final implementation, we're here to support you every step of the way.
        </p>
      </div> */}
      <div className="flex flex-col sm:flex-row justify-around items-start gap-  max-w-[1500px] m-auto  ">
        <div className='flex flex-grow flex-col gap-6 justify-between items-center' style={{ height: '100%' }}>
          <h1 className='text-4xl text-orange-600 font-medium'>Our Services</h1>
          <img loading="lazy"  className='sm:w-[7.5vw] h-full opacity-0' src={servicesData[0].img} alt="" />
        </div>
        <div className='sm:w-[65%]'>
          <p className=' text-2xl font-normal' style={{ height: '100%' }}>
            We specialize in turning your digital vision into reality. Our team of experts works closely with you to develop tailored solutions that perfectly suit your unique needs. From initial brainstorming to final implementation, we're here to support you every step of the way.
          </p>
        </div>
      </div>

      <hr className='my-12 border-gray-300' />

      {servicesData.map((service, index) => (
        <React.Fragment key={index}>
          <Service
            href={service.href}
            img={service.img}
            title={service.title}
            description={service.description}
          />
          <hr className='my-12 border-gray-300' />
        </React.Fragment>
      ))}

      <div className="flex flex-col sm:flex-row justify-around items-start gap-  max-w-[1500px] m-auto  ">
        <div className='flex flex-grow flex-col opacity-0 gap-6 justify-between items-center' style={{ height: '100%' }}>
          <h1 className='text-4xl text-orange-600 font-medium'>Our Services</h1>
          <img loading="lazy"  className='sm:w-[7.5vw] h-full' src={servicesData[0].img} alt="" />
        </div>
        <div className='sm:w-[65%]'>
          <p className='mt-6 text-orange-500 text-2xl'>
            At ClekkFlix, we don’t just deliver services; we partner with you to drive real digital transformation. Working with talented agencies and companies, we’re focused on making an impact where it matters most, your success.
          </p>

          <div className="mt-10">
            <p>Have a digital project in mind?</p>
            <Link to="/contact-us"><Button className='mt-2 text-3xl'>Contact us</Button></Link>
          </div>
        </div>
      </div>
      <div className='sm:w-[65%] float-right px-5 max-w-[1500px]'>

      </div>
    </ComeUpPageWrapper>
  );
};

export default Services;
