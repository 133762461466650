import React, { useEffect } from 'react';
import ComeUpPageWrapper from '../components/ComeUpPageWrapper';
import Feature from '../components/FeatureCard';
import { Link } from 'react-router-dom';
import Button from '../components/buttons/Button';
import featuresData from '../content/WhyChooseUs.json';

const WhyChooseUs = () => {
  useEffect(() => { document.title = "why choose us" }, []);

  return (
    <ComeUpPageWrapper>
      {/* <div className="flex flex-col text-center sm:text-left sm:flex-row justify-around mb-14 max-w-[1500px] m-auto">
        <h1 className='text-4xl text-orange-600 font-medium'>Our Expertise</h1>
        <p className='sm:w-[65%] text-2xl font-normal'>Our team of around 20 passionate experts designs personalised digital solutions. From advice to design to technical implementation, we make your entire digital journey.
        </p>
      </div> */}
      <div className="flex flex-col sm:flex-row justify-around gap-4 w-full items-start max-w-[1500px] m-auto  ">
        <div className="sm:w-[25%] w-full">
          <h1 className='text-4xl  text-orange-600 font-medium text-center sm:text-left w-full'>Our Expertise</h1>
        </div>
        <div className='sm:w-[75%] w-full text-center sm:text-left' >
          <p className='text-2xl font-normal' style={{ height: '100%' }}>
            Our team of around 20 passionate experts designs personalised digital solutions. From advice to design to technical implementation, we make your entire digital journey
          </p>
        </div>
      </div>
      <hr className='my-12 border-gray-300' />

      {featuresData.map((feature, index) => (
        <div key={index}>
          <Feature
            img={feature.img}
            title={feature.title}
            description={feature.description}
          />
          <hr className='my-12 border-gray-300' />
        </div>
      ))}

      <div className="flex flex-col sm:flex-row justify-around gap-4 w-full items-start max-w-[1500px] m-auto  ">
        <div className="sm:w-[25%] w-full opacity-0">
          <h1 className='text-4xl  text-orange-600 font-medium text-center sm:text-left w-full'>Our Expertise</h1>
        </div>
        <div className='sm:w-[75%] w-full text-center sm:text-left' >
          <p className='mt-6 text-orange-500 text-2xl'>
            We believe that digital transformation requires more than just expertise, it requires
            collaboration and innovation. That’s why we partner with forward-thinking agencies and
            companies to make sure your project is a success.
          </p>

          <div className="mt-10">
            <p>Have a digital project in mind?</p>
            <Link to={"/contact-us"}><Button className='mt-2 text-3xl'>Contact us</Button></Link>
          </div>
        </div>
      </div> 
    </ComeUpPageWrapper>
  );
};

export default WhyChooseUs;
