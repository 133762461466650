import React, { useEffect, useState } from 'react'
import ComeUpPageWrapper from '../components/ComeUpPageWrapper'
import { Link } from 'react-router-dom'
import { CheckCircle } from 'lucide-react'
import emailjs from '@emailjs/browser';

const map = require("../media/gmap.png")
const logo = require("../media/logo.png")
const Contact = () => {
  const [submit, SetSubmit] = useState<boolean>(false)
  const [loading, SetLoading] = useState<boolean>(false)
  useEffect(() => { document.title = "contact us" }, []);
  function sendEmail(e: React.FormEvent) {
    if(loading)return
    e.preventDefault(); 
    SetLoading(true)
    emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICEID||"",process.env.REACT_APP_EMAILJS_TEMPLATEID||"", e.currentTarget as HTMLFormElement, process.env.REACT_APP_EMAILJS_USERID||"")
      .then((result) => {
        SetSubmit(true);
      }, (error) => {
        console.log(error)
        alert("error occured. please try again later")
      });
      SetLoading(false)
    }



  // const handleSubmit = (event: React.FormEvent) => {
  //   event.preventDefault();
  //   if(loading)return
  //   const myForm = event.target as HTMLFormElement;
  //   const formData = new FormData(myForm);
  //   const formDataParams = new URLSearchParams();

  //   formData.forEach((value, key) => {
  //     formDataParams.append(key, value.toString());
  //   });
  //   fetch("/", {
  //     method: "POST",
  //     headers: { "Content-Type": "application/x-www-form-urlencoded" },
  //     body: formDataParams.toString()
  //   })
  //     .then(() => SetSubmit(true))
  //     .catch(error => {
  //       alert("error occured. please try again later")
  //     });
  // };
  return (
    <ComeUpPageWrapper >
      <div className="flex flex-col sm:flex-row justify-between mb-14 gap-3 max-w-[1500px] m-auto">
        <h1 className='text-4xl text-orange-600 font-medium'>Lets Work Together</h1>
        <p className='sm:w-[65%] text-2xl font-normal'>Our dedicated team offers comprehensive digital solutions that are designed to meet your exact requirements. We partner with you throughout the entire process, providing expert guidance and ensuring a successful outcome.
        </p>
      </div>
      <div className='flex flex-col max-w-[1500px] m-auto'>
        <h2 className='text-4xl'>Contact us</h2>

        <div className="flex flex-col sm:flex-row gap-14 mt-6 justify-between">
          {submit ?
            <div className="flex flex-col justify-center text-center text-orange-500">
              <CheckCircle size={45} className='mx-auto mb-3 text-orange-600' />
              <h1 className='text-xl font-bold'>Thanks for getting in touch</h1>
              <h3 className='text-sm'>We'll get back to you soon</h3>
            </div>
            :
            <form onSubmit={sendEmail} className="space-y-4 max-w-[800px] flex-grow" name="contact" data-netlify="true" netlify-honeypot="bot-field" method='post'>
              <div>
                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 ">Your email</label>
                <input name='email' className='border w-full border-gray-500 px-3 py-2' type="email" id="email" placeholder="name@flowbite.com" required />
              </div>
              <div>
                <label htmlFor="subject" className="block mb-2 text-sm font-medium text-gray-900 ">Subject</label>
                <input name='subject' className='border w-full border-gray-500 px-3 py-2' type="text" id="subject" placeholder="Let us know how we can help you" required />
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 ">Your message</label>
                <textarea name='comment' className='border w-full border-gray-500 px-3 py-2' id="message" rows={6} placeholder="Leave a comment..."></textarea>
              </div>
              <button disabled={loading} className='px-2 py-1 border rounded-full border-black disabled:opacity-50 hover:bg-black/35 transition duration-300  '>
                Send Message
              </button>
            </form>}


          <div className="sm:w-[40%] space-y-2">
            <div className="">
              <img loading="lazy"  src={logo} width={125} className='mb-3' alt="" />
              <h2 className='font-medium text-gray-800'>Address:</h2>
              <p className='mb-2'>Eureka Towers C-802, Chincholi Bunder Rd, Mindspace, Malad West, Mumbai - 400064, Maharashtra, India.</p>
              <Link
                className='text-blue-800 hover:text-blue-950 transition duration-500 underline'
                target='_blank'
                to="https://maps.app.goo.gl/kND2y4Hm3SYvJHMZA">
                <img loading="lazy"  width={20} className='inline' src={map} alt="google map" />
                View in map
              </Link>
            </div>

            <div className="">
              <h2 className='font-medium text-gray-800'>Phone:</h2>
              <Link
                className='text-blue-800 hover:text-blue-950 transition duration-500 underline'
                to={"tel:+919920010885"}>
                +91 99200 10885
              </Link>
              <p></p>
            </div>

            <div className="">
              <h2 className='font-medium text-gray-800'>E-mail:</h2>
              <Link
                className='text-blue-800 hover:text-blue-950 transition duration-500 underline'
                to={"mailto:info@clekk.co.in"}>
                info@clekk.co.in
              </Link>
            </div>
          </div>

        </div>

      </div>
    </ComeUpPageWrapper>
  )
}

export default Contact
