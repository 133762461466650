import { useEffect, useRef, useState } from 'react';
import GalleryFilter from '../../GalleryFilter';
import GalleryPotrait, { Potrait } from '../../GalleryPotrait';
import galleryData from '../../../content/Gallery.json';

const GallerySection = () => {
    const element = useRef<HTMLDivElement>(null);
    const [hovered, setHover] = useState<number | undefined>(undefined);
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]); // Track selected categories
    const [Categories, setCategories] = useState<Record<string,number>>({}); // Track  categories
    
    // Handle filter selection and deselection
    const handleFilterClick = (category: string) => {
        setSelectedCategories((prevSelectedCategories) => {
            if (prevSelectedCategories.includes(category)) {
                // If the category is already selected, remove it
                return prevSelectedCategories.filter((cat) => cat !== category);
            } else {
                // Otherwise, add it
                return [...prevSelectedCategories, category];
            }
        });
    };

    // Filter the gallery based on selected categories
    const filteredGalleryData = selectedCategories.length
        ? galleryData.filter((group) => selectedCategories.includes(group.category)) // Show images matching any selected category
        : galleryData; // Show all if no categories are selected

    useEffect(()=>{
        const obj:Record<string,number> = {}
        galleryData.map(g=>obj[g.category]=(obj[g.category]||0)+1)
        setCategories(obj)
    },[])    
    return (
        <section
            onClick={() => localStorage.setItem('scroll-position', 'portfolio')}
            id='portfolio'
            style={{ backgroundColor: '#181717' }}
            className='p-9 py-12 text-white gap-16'
        >
            <h1 className='text-5xl md:text-[4.5vw] whitespace-nowrap text-center mb-7 text-orange-600'>
               Our Portfolio
            </h1>

            {/* Filters */}
            <div className='flex gap-6 flex-wrap justify- mb-7'>
                {Object.keys(Categories).map((cat, i) => (
                    <GalleryFilter
                        key={i}
                        length={Categories[cat]}
                        onClick={() => handleFilterClick(cat)}  
                        isActive={selectedCategories.includes(cat)} 
                    >
                        {cat}
                    </GalleryFilter>
                ))}
            </div>

            {/* Gallery */}
            <div ref={element} className='flex flex-col sm:flex-row flex-wrap gap-12'>
                {filteredGalleryData.flat().map((potrait, i) => (
                    <div
                        key={i}
                        className='lg:w-[30%] sm:w-[45%] w-full'
                    >
                            <GalleryPotrait
                                key={`${i}-${i}`}
                                overlay={hovered?hovered!==i:false}
                                onHover={() => setHover(i)}
                                onLeave={() => setHover(undefined)}
                                potrait={potrait as Potrait}
                            />
                    </div>
                ))}
            </div>
 
        </section>
    );
};

export default GallerySection;
